export const getCourseAPI = (limit = 12, offset = "", search = "") => {
  let url = `${process.env.REACT_APP_IBL_DM_URL}/api/search/catalog/?content=courses&limit=${limit}`;
  
  if (offset) {
    url += `&offset=${offset}`;
  }
  
  if (search) {
    url += `&query=${encodeURIComponent(search)}`;
  }

  return fetch(url)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      return data || [];
    })
    .catch((error) => {
      console.error('Error fetching courses:', error);
      return [];
    });
};


